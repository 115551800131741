import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Section,
  CustomButton,
  Title,
  Description,
  Row,
  Column,
  Collapsible,
  TitleEsp,
  TitlePrice,
  TitleComments,
  DivTitle,
  TitleLast,
  Observacoes
} from './lpPriceBoard.styles';

import ContentChild from './lpListEspecialidades';
import ListMore from './lpListMore';
import priceListSaojosedoscampos from '../../content/priceListSaojosedoscampos.json';
import priceListJacarei from '../../content/priceListJacarei.json';
import priceListPontagrossa from '../../content/priceListPontagrossa.json';
import priceListCuritibaCajuru from '../../content/priceListCuritibaCajuru.json'
import priceListCuritibaCajuruExames from '../../content/priceListCuritibaCajuruExames.json'
import priceListCuritibaBoaVista from '../../content/priceListCuritibaBoaVista.json'
import priceListCuritibaCentro from '../../content/priceListCuritibaCentro.json'
import priceListCuritibaFazendinha from '../../content/priceListCuritibaFazendinha.json'
import priceListMogi from '../../content/priceListMogi.json'
import priceListLondrina from '../../content/priceListLondrina.json';
import priceListLondrinaExames from '../../content/priceListLondrinaExames.json';
import priceListApucarana from '../../content/priceListApucarana.json';
import priceListApucaranaExames from '../../content/priceListApucaranaExames.json';
import priceListPiracicaba from '../../content/priceListPiracicaba.json';
import priceListFlorianopolis from '../../content/priceListFlorianopolis.json';
import priceListSantos from '../../content/priceListSantos.json';
import priceListCaraguatatuba from '../../content/priceListCaraguatatuba.json';
import priceListRibeiraoPreto from '../../content/priceListRibeiraoPreto.json';
import priceListSorocaba from '../../content/priceListSorocaba.json';
import priceListAraucariaPrefeitura from '../../content/priceListAraucariaPrefeitura.json'
import priceListChapeco from '../../content/priceListChapeco.json'
import priceListCriciuma from '../../content/priceListCriciuma.json'
import priceListUmuarama from '../../content/priceListUmuarama.json'
import priceListSalvador from '../../content/priceListSalvador.json'
import priceListBlumenau from '../../content/priceListBlumenau.json'

export default function LpPriceBoard( { data } ) {

  const [ showList, setShowList ] = useState( true )
  const [ showMoreList, setShowMoreList ] = useState( true )
  const [ showMoreListButton, setShowMoreListButton ] = useState( true )
  const [ result, setResult ] = useState()
  const [ parcelamento, setParcelamento ] = useState( "" )
  const [ aPartirDe, setaPartirDe ] = useState( false )

  useEffect( () => {
    switch ( true ) {
      case data.data.slug === "saojosedoscampos":
        //LISTA DE PRECO
        let orderList = priceListSaojosedoscampos.sort( ( a, b ) => ( a.titulo > b.titulo ) ? 1 : ( ( b.titulo > a.titulo ) ? -1 : 0 ) )
        setResult( orderList );
        setShowMoreListButton( false )
        break;

      case data.data.slug === "jacarei":
        //LISTA DE PRECO
        let orderListJacarei = priceListJacarei.sort( ( a, b ) => ( a.titulo > b.titulo ) ? 1 : ( ( b.titulo > a.titulo ) ? -1 : 0 ) )
        setResult( orderListJacarei );
        setShowMoreListButton( false )
        break;

      case data.data.slug === "araucaria-prefeitura":
        //LISTA DE PRECO
        setResult( priceListAraucariaPrefeitura );
        break;

      case data.data.slug === "blumenau":
        //LISTA DE PRECO
        setResult( priceListBlumenau );
        break;

      case data.data.slug === "chapeco":
        //LISTA DE PRECO
        setResult( priceListChapeco );
        break;

      case data.data.slug === "criciuma":
        //LISTA DE PRECO
        setResult( priceListCriciuma );
        break;

      case data.data.slug === "piracicaba":
        //LISTA DE PRECO
        setResult( priceListPiracicaba );
        break;

      case data.data.slug === "pontagrossa":
        //LISTA DE PRECO
        setResult( priceListPontagrossa );
        break;

      case data.data.slug === "curitiba-cajuru":
        //LISTA DE PRECO
        setResult( priceListCuritibaCajuru );
        break;

      case data.data.slug === "curitiba-cajuru-exames":
        //LISTA DE PRECO
        setResult( priceListCuritibaCajuruExames );
        break;

      case data.data.slug === "curitiba-boavista":
        //LISTA DE PRECO
        setResult( priceListCuritibaBoaVista );
        break;

      case data.data.slug === "curitiba-centro":
        //LISTA DE PRECO
        setResult( priceListCuritibaCentro );
        break;

      case data.data.slug === "curitiba-fazendinha":
        setResult( priceListCuritibaFazendinha );
        break;

      case data.data.slug === "mogidascruzes":
        //LISTA DE PRECO
        setResult( priceListMogi );
        break;

      case data.data.slug === "ribeiraopreto":
        //LISTA DE PRECO
        setResult( priceListRibeiraoPreto );
        break;

      case data.data.slug === "sorocaba":
        //LISTA DE PRECO
        setResult( priceListSorocaba );
        break;

      case data.data.slug === "santos":
        setResult( priceListSantos );
        break;

      case data.data.slug === "caraguatatuba":
        setResult( priceListCaraguatatuba );
        break;

      case data.data.slug === "londrina":
      case data.data.slug === "cambe":
      case data.data.slug === "ibipora":
        setResult( priceListLondrina );
        setaPartirDe( true )
        setParcelamento( "com parcelamento em até 12x e pagamento online" );
        break;

      case data.data.slug === "florianopolis":
      case data.data.slug === "palhoca":
      case data.data.slug === "saojose":
        setResult( priceListFlorianopolis );
        break;

      case data.data.slug === "londrina-exames":
        setResult( priceListLondrinaExames );
        setaPartirDe( true )
        setParcelamento( "com parcelamento em até 12x sem juros no cartão!" );
        break;

      case data.data.slug === "apucarana-exames":
        setResult( priceListApucaranaExames );
        setaPartirDe( true )
        setParcelamento( "com parcelamento em até 12x sem juros no cartão!" );
        break;

      case data.data.slug === "apucarana":
      case data.data.slug === "arapongas":
        setResult( priceListApucarana );
        setaPartirDe( true )
        //setParcelamento( "com parcelamento em até 12x e pagamento online" );
        break;

      case data.data.slug === "umuarama":
        setResult( priceListUmuarama );
        setaPartirDe( true )
        break;

      case data.data.slug === "salvador":
        setResult( priceListSalvador );
        setaPartirDe( true )
        break;

      default:
        console.log( "Fail List" );
    }

    setTimeout( () => {
      setShowList( false )
      setShowMoreList( false )
    }, 1000 );

  }, [ data ] )

  const callWhatsApp = () => {
    window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.` )
  }

  return (
    <Section id="especialidades">
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>{data.data.slug === 'salvador'
          ? "Sua Saúde com Mais Economia e Praticidade!"
          : "VALORES ACESSÍVEIS"
        }</Title>
        {data.data.slug === 'salvador'
          ? <Description>Condições* especiais praticadas pela franquia em Salvador</Description>
          : <Description>Preços* especiais praticados por {data.data.nomefantasia} <strong>{parcelamento}</strong></Description>
        }
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "30px" }}>
        <Row>

          {result ?
            <>
              {result.map( ( item, index ) => (
                <Column key={index}>
                  <Collapsible>
                    <DivTitle>

                      <TitleEsp>
                        {item.titulo}
                        {item.preco === "0,00" ? <></> :
                          <>
                            {aPartirDe ?
                              <>
                                <span style={{ fontSize: "14px", fontWeight: "150", color: "white" }}> a partir de:</span>
                              </>
                              :
                              <></>
                            }
                          </>
                        }
                      </TitleEsp>

                      <TitleComments>{item?.comments}</TitleComments>

                    </DivTitle>
                    {item.preco === "0,00" ? <></> :
                      <TitlePrice>
                        <span style={{ fontSize: "14px", fontWeight: "300" }}>R$</span>
                        {item.preco}
                        <small style={{ fontWeight: "normal" }}>*</small>
                      </TitlePrice>
                    }
                  </Collapsible>
                </Column>
              ) )}
            </> : <></>
          }

          {showMoreListButton ?
            <Column>
              <Collapsible>
                <TitleLast onClick={() => setShowList( !showList )}>
                  {showList ? "Lista " : "Ver lista "} de especialidades atendidas <strong>{showList ? "-" : "+"}</strong>
                </TitleLast>
              </Collapsible>
            </Column>
            :
            <Column>
              <Collapsible>
                <TitleEsp onClick={() => callWhatsApp()}>
                  E muito mais. Entre em contato conosco.
                </TitleEsp>
              </Collapsible>
            </Column>
          }
        </Row>
        {showList && <ContentChild data={data} />}

        {showList && data.data.slug === "pontagrossa" &&
          <>
            <Row>
              <Column>
                <Collapsible>
                  <TitleLast onClick={() => setShowMoreList( !showMoreList )}>
                    {showMoreList ? "Outras " : "Ver outras "}especialidades <strong>{showMoreList ? "-" : "+"}</strong>
                  </TitleLast>
                </Collapsible>
              </Column>
            </Row>
            {showMoreList && <ListMore />}
          </>
        }

        <Row style={{ justifyContent: 'center' }}>
          <CustomButton
            onClick={callWhatsApp}
            color={"#2a4f72"}
            background={"#fdd460"}
            width={"70%"}>AGENDE AGORA</CustomButton>
        </Row>

        <Observacoes>
          * Valores a partir de e sujeitos a alteração sem prévio aviso.
          <span>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.`}
              style={{ textDecoration: "none", color: "#007bff" }}
            > Consulte </a>
          </span>
          outras especialidades e disponibilidade de agenda em nosso atendimento.
        </Observacoes>
        {/* {data.data.slug === "apucarana" ?
          <Observacoes style={{ color: "#ff5c55", marginTop: "5px" }}>*Consultar disponibilidade nas regiões</Observacoes>
          :
          <></>
        } */}

      </Flex>
    </Section>
  )
}