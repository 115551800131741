import React, { useEffect, useState } from 'react';

import { Header } from '../../components/LpHeader/header';
import { Footer } from './LpFooterOnline/footer';

import ModalForm from '../../components/ModalForm/modalForm';
import LpBannerA from '../../components/LpBannerA/lpBannerA';
import LpBannerAB from '../ModelA/LpBannerAB/lpBannerAB';
import LpMapa from '../../components/LpMapa/lpMapa';
import LpPriceBoard from '../../components/LpPriceBoard/lpPriceBoard';
import AgendeBoard from '../../components/AgendeBoard/agendeBoard';
import GridEspecialidades from '../../components/GridEspecialidades/gridEspecialidades';
import CarouselExames from '../../components/CarouselExames/carouselExames';

export default function ModelA( { data } ) {

  const [ infoPrice, setInfoPrice ] = useState( '70' );
  const [ changeShow, setChangeShow ] = useState( false );
  const [ onLpBannerA, setOnLpBannerA ] = useState( true );
  const [ onLpBannerAB, setOnLpBannerAB ] = useState( false );
  const [ showSpecialties, setShowSpecialties ] = useState( true );
  const [ onLpPrice, setOnLpPrice ] = useState( true );
  const setShow = () => {
    setChangeShow( !changeShow )
  }

  var infoTag = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MXMKSK9R');`;

  useEffect( () => {
    
    if ( data.data.slug === 'blumenau' ) {
      setShowSpecialties( false )
    }
    if (data.data.slug === 'campomagro') {
      setOnLpPrice( false )
    }
    if (data.data.slug === 'quatro-barras') {
        setOnLpPrice( false )
    }
    if (data.data.slug === 'tubarao') {
          setOnLpPrice( false )
    }
    if (data.data.slug === 'ararangua') {
      setOnLpPrice( false )
    }
    if (data.data.slug === 'riobrancodosul') {
      setOnLpPrice( false )
    }
    if (data.data.slug === 'curitiba-orleans') {
      setOnLpPrice( false )

      const $pixelScript = document.createElement( "script" );
      document.head.appendChild( $pixelScript );
      $pixelScript.innerHTML = infoTag;

      const $pixelNoscript = document.createElement( "noscript" );
      document.body.appendChild( $pixelNoscript );

      const $iframeNoscript = document.createElement( "iframe" );

      $iframeNoscript.height = "0";
      $iframeNoscript.width = "0";
      $iframeNoscript.style = "display:none,visibility:hidden";
      $iframeNoscript.src = "https://www.googletagmanager.com/ns.html?id=GTM-MXMKSK9R";

      $pixelNoscript.appendChild( $iframeNoscript )

      return () => {
        document.head.removeChild( $pixelScript );
      }
    }
  }, [] )

  return (
    <>
      {changeShow ?
        <>
          <ModalForm data={data} setShow={setShow} />
        </>
        : <></>}

      <Header email={data.data.email_adm} />

      {onLpBannerA && <LpBannerA data={data} />}
      {onLpBannerAB && <LpBannerAB data={data} infoPrice={infoPrice} />}

      <LpMapa data={data} setShow={setShow} />

      {onLpPrice && <LpPriceBoard data={data} />}

      <AgendeBoard data={data} infoPrice={infoPrice} />

      {showSpecialties && <GridEspecialidades />}

      <CarouselExames data={data} />
      <Footer data={data} />
    </>
  );
}