import React, { useEffect, useState } from 'react';

import { ContactWAForm } from '../ContactForm/contactWAForm';
import { requestForm } from '../../helpers/requestForm';

import FormTeste from './form-teste';

import { GtagWhatsApp } from './GtagWhatsApp';
import {
  WAhref,
  Icone
} from './whatsAppButton.styles';

const sendWhatsApp = ( telefone_whats ) => {
  if ( telefone_whats ) {
    const numeroLimpo = telefone_whats.replace( /[^\d]+/g, '' );
    window.open( `https://api.whatsapp.com/send?phone=55${numeroLimpo}&text=Ol%C3%A1,%20aqui%20%C3%A9%20a%20Solfia`, "_blank" );
  }
};



export function WhatsAppButton( { number, text, pathname, slug } ) {
  const [ solfia, setSolfia ] = useState( false );
  const [ show, setShow ] = useState( true );
  const [ thisNumber, setThisNumber ] = useState( '' );
  const [ callGtagWhatsApp, setCallGtagWhatsApp ] = useState( false );
  const [ addScript, setAddScript ] = useState( false );
  const [ tags, setTags ] = useState( { gtag: '', address: '' } );
  const [ showForm, setShowForm ] = useState( false );


  useEffect( () => {
    if ( number ) {
      let str = number.replace( /[^\d]+/g, '' )
      setThisNumber( str )
    }

    if ( number === '(41) 9 9846-0246' ) {
      setShow( false )
    }

    if ( number === '(41) 9 9718-0188' ) {
      setSolfia( true )
    }

    if ( pathname === '/londrina' ) {
      // LONDRINA
      setTags( { gtag: 'AW-613684913', address: 'Pnf_CIu9k4sYELGt0KQC' } )
      setAddScript( true )
    }

    if ( pathname === '/cambe' ) {
      // CAMBE
      setTags( { gtag: 'AW-11085614779', address: 'CxZgCLOTxJMYELudhKYp' } )
      setAddScript( true )
    }

    if ( pathname === '/ibipora' ) {
      // IBIPORA
      setTags( { gtag: 'AW-613684913', address: 'Pnf_CIu9k4sYELGt0KQC' } )
      setAddScript( true )
    }

    if ( number === '(41) 9 8889-2641' ) {
      // CURITIBA-BOAVISTA
      setTags( { gtag: 'AW-10926418453', address: '' } )
      setAddScript( true )
    }

  }, [ number, pathname ] )




  var infoTag = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${tags.gtag}');

  
  `

  useEffect( () => {
    if ( addScript ) {
      const gtagScript = document.createElement( "script" );
      document.head.appendChild( gtagScript );
      gtagScript.async = true
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${tags.gtag}`

      const gtagScriptSnippet = document.createElement( "script" );
      document.head.appendChild( gtagScriptSnippet );
      gtagScriptSnippet.innerHTML = infoTag;

      return () => {
        document.head.removeChild( gtagScript );
        document.head.removeChild( gtagScriptSnippet );
      }
    }
  }, [ addScript, infoTag, tags.gtag ] )


  function callWA() {
    if ( solfia ) {
      window.open( `https://api.whatsapp.com/send?phone=55${thisNumber}&text=Ol%C3%A1,%20aqui%20%C3%A9%20a%20Solfia`, "_blank" );
      return;
    }

    if (
      thisNumber === "4333765801" ||
      thisNumber === "4333765805" ||
      thisNumber === "4333765806" ||
      thisNumber === "4333765808" ||
      thisNumber === "4333765809" ||
      thisNumber === "4333765815" ||
      thisNumber === "5437714100"
    ) {
      setCallGtagWhatsApp( true );
      return;
    }
    window.open( `https://api.whatsapp.com/send?phone=55${thisNumber}&text=${text}`, "_blank" );
  }

  function handleClick( params ) {
    setShowForm( false );
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    callWA();
  }

  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
      {show && (
        <WAhref onClick={() => {
          if ( false ) { // thisNumber === '8487855824'
            setShowForm( true )
          } else {
            callWA()
          }
        }}>
          <Icone id={"flutuante"}></Icone>
        </WAhref>
      )}

      {callGtagWhatsApp && <GtagWhatsApp tags={tags} />}

      {showForm && <ContactWAForm handleClick={handleClick} />}

    </div>
  );
}