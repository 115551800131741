import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../assets/styles/global';

import {
  TitleA,
  TitleB,
  MainDiv,
  Row,
  ColumnA,
  ColumnB,
  CartaoSolumediImg,
  DivOff,
  Description
} from './bannerA.styles';

import CartaoSolumedi from '../../assets/image/cartaoSolumedi.png';
import { CustomButton } from '../../components/CustomButton/customButton';

export default function BannerA() {

  const history = useHistory()

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#eaeaea"} >
        <TitleA onClick={() => history.push('/sobrenos/cartao')}>
          Saiba como usar o seu cartão
          <span style={{ color: "#2a4f72", fontWeight: "900" }}>
            &nbsp;Solumedi
          </span>
        </TitleA>
      </Flex>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} Background={"#fafafa"} style={{ marginBottom: "30px" }} >
        <MainDiv>
          <Row>
            <ColumnA>
              <CartaoSolumediImg src={CartaoSolumedi} alt="cartao_solumedi"/>
            </ColumnA>
            <ColumnB>
              <TitleB onClick={() => history.push('/sobrenos/cartao')}>
                O que é o cartão
                <span style={{ fontWeight: "900", wordBreak: "keep-all" }}>
                  &nbsp;Solumedi?</span>
              </TitleB>
              <DivOff>
                <Description>É um cartão gratuito, fácil e rápido de ser utilizado, sem cobranças de mensalidades e anuidades, ou seja, você só paga quando precisa usar, e com ele você pode fazer agendamentos de consultas e exames particulares, além de diversos outros procedimentos clínicos, e tudo isso com valores reduzidos.</Description>
                <CustomButton
                  onClick={() => history.push('/unidades/buscar')}
                  width={"25%"}
                  margin={"25px 0px 0px 0px"} >EU QUERO</CustomButton>
              </DivOff>
            </ColumnB>
          </Row>
        </MainDiv>
      </Flex>
    </>
  );
}